import { lazy } from 'react';

import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import Main from 'app/layouts/Main';
import AddonsRoute from 'components/AddonsRoute';
import ErrorBoundary from 'components/ErrorBoundary';
import ImportAllowedRoute from 'components/ImportAllowedRoute';
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import SmartProductsRoute from 'components/SmartProductsRoute';
import SupportDeskRoute from 'components/SupportDeskRoute';
import retry from 'helpers/retry';

const Home = lazy(() => retry(() => import(/* webpackChunkName: "Home" */ 'routes/Home')));
const Reminders = lazy(() =>
  retry(() => import(/* webpackChunkName: "Reminders" */ './app/reminders'))
);
const Companies = lazy(() =>
  retry(() => import(/* webpackChunkName: "Companies" */ './app/companies'))
);
const AuthLayout = lazy(() =>
  retry(() => import(/* webpackChunkName: "AuthLayout" */ './app/layouts/Auth'))
);
const ForgotPassword = lazy(() =>
  retry(() => import(/* webpackChunkName: "ForgotPassword" */ 'routes/ForgotPassword'))
);
const ErrorPage = lazy(() =>
  retry(() => import(/* webpackChunkName: "ErrorPage" */ 'components/ErrorPage'))
);
const NoMatch = lazy(() => retry(() => import(/* webpackChunkName: "NoMatch" */ 'routes/NoMatch')));
const SharedActivity = lazy(() =>
  retry(() => import(/* webpackChunkName: "SharedActivity" */ 'routes/SharedActivity'))
);
const RequestAccess = lazy(() =>
  retry(() => import(/* webpackChunkName: "RequestAccess" */ 'routes/AccessToSpiro'))
);
const Activities = lazy(() =>
  retry(() => import(/* webpackChunkName: "Activities" */ './app/activities'))
);
const Contacts = lazy(() =>
  retry(() => import(/* webpackChunkName: "Contacts" */ './app/contacts'))
);
const Opportunities = lazy(() =>
  retry(() => import(/* webpackChunkName: "Opportunities" */ './app/opportunities'))
);
const Campaigns = lazy(() =>
  retry(() => import(/* webpackChunkName: "Campaigns" */ './app/campaigns'))
);
const Tickets = lazy(() => retry(() => import(/* webpackChunkName: "Tickets" */ './app/tickets')));
const Orders = lazy(() => retry(() => import(/* webpackChunkName: "Orders" */ './app/orders')));
const Quotes = lazy(() => retry(() => import(/* webpackChunkName: "Quotes" */ './app/quotes')));
const Calendar = lazy(() =>
  retry(() => import(/* webpackChunkName: "Calendar" */ 'routes/CalendarView'))
);
const Lists = lazy(() => retry(() => import(/* webpackChunkName: "Lists" */ './app/lists')));
const Assistant = lazy(() =>
  retry(() => import(/* webpackChunkName: "Assistant" */ './app/assistant'))
);
const EngineRules = lazy(() =>
  retry(() => import(/* webpackChunkName: "EngineRules" */ './app/engine-rules'))
);
const Import = lazy(() => retry(() => import(/* webpackChunkName: "Import" */ 'routes/Import')));
const Settings = lazy(() =>
  retry(() => import(/* webpackChunkName: "Settings" */ 'routes/Settings'))
);
const Onboarding = lazy(() =>
  retry(() => import(/* webpackChunkName: "OnboardingLayout" */ 'routes/Onboarding'))
);

const RequestFullLicence = lazy(() =>
  retry(() => import(/* webpackChunkName: "RequestFullLicence" */ 'components/RequestFullLicence'))
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ErrorBoundary>
              <Main />
            </ErrorBoundary>
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
        <Route path="/reminders/*" element={<Reminders />} />
        <Route path="/companies/*" element={<Companies />} />
        <Route path="/contacts/*" element={<Contacts />} />
        <Route path="/activities/*" element={<Activities />} />
        <Route path="/opportunities/*" element={<Opportunities />} />
        <Route path="/campaigns/*" element={<Campaigns />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/lists/*" element={<Lists />} />
        <Route path="/assistant/*" element={<Assistant />} />
        <Route path="/assistant-rules/*" element={<EngineRules />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route
          path="/tickets/*"
          element={
            <SupportDeskRoute>
              <Tickets />
            </SupportDeskRoute>
          }
        />
        <Route
          path="/quotes/*"
          element={
            <SmartProductsRoute>
              <Quotes />
            </SmartProductsRoute>
          }
        />
        <Route
          path="/orders/*"
          element={
            <AddonsRoute addonName="Sales Orders">
              <Orders />
            </AddonsRoute>
          }
        />
        <Route
          path="/import/*"
          element={
            <ImportAllowedRoute>
              <Import />
            </ImportAllowedRoute>
          }
        />
        <Route path="/company/:id" element={<Companies />} />
        <Route path="/contact/:id" element={<Contacts />} />
        <Route path="/opportunity/:id" element={<Opportunities />} />
      </Route>
      <Route
        path="/login/*"
        element={
          <PublicRoute>
            <ErrorBoundary>
              <AuthLayout />
            </ErrorBoundary>
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoute>
            <ErrorBoundary>
              <ForgotPassword />
            </ErrorBoundary>
          </PublicRoute>
        }
      />
      <Route
        path="/onboarding/*"
        element={
          <PrivateRoute>
            <ErrorBoundary>
              <Onboarding />
            </ErrorBoundary>
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NoMatch />} />
      <Route path="/page-not-found" element={<NoMatch />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/request-access" element={<RequestAccess />} />
      <Route path="/home" element={<Navigate to="/" />} />
      <Route path="/reset_password" element={<Navigate to="/reset-password" />} />
      <Route
        path="/share"
        element={
          <ErrorBoundary>
            <SharedActivity />
          </ErrorBoundary>
        }
      />
      <Route path="/request-full-licence" element={<RequestFullLicence />} />
    </>
  )
);

export default router;
