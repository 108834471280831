export const sortBy = (array, keys, order = {}) => {
  const getSortOrder = (key) => ((order[key] || 'asc') === 'asc' ? 1 : -1);

  return array.sort((a, b) =>
    keys.reduce((result, key) => {
      if (result !== 0) return result;

      const sortOrder = getSortOrder(key);
      const valA = a[key] !== undefined && a[key] !== null ? a[key] : null;
      const valB = b[key] !== undefined && b[key] !== null ? b[key] : null;

      // Handle missing or null/undefined values
      if (valA === null && valB !== null) return 1; // Place `null`/`undefined` at the end
      if (valA !== null && valB === null) return -1;
      if (valA === null && valB === null) return 0;

      // Handle case-insensitive string comparison
      const valAFormatted = typeof valA === 'string' ? valA.toLowerCase() : valA;
      const valBFormatted = typeof valB === 'string' ? valB.toLowerCase() : valB;

      // Standard comparison
      if (valAFormatted > valBFormatted) return sortOrder;
      if (valAFormatted < valBFormatted) return -sortOrder;

      return 0;
    }, 0)
  );
};
