import PropTypes from 'prop-types';

import ResourceLookup from '../ResourceLookup/ResourceLookup';

function OpportunityLookup({ inline = false, ...props }) {
  return <ResourceLookup {...props} inline={inline} resource="opportunity" />;
}

OpportunityLookup.propTypes = {
  inline: PropTypes.bool,
};

export default OpportunityLookup;
