import PropTypes from 'prop-types';

import ResourceLookup from '../ResourceLookup/ResourceLookup';

function SalesOrderLookup({ inline = false, ...props }) {
  return <ResourceLookup {...props} inline={inline} resource="sales_order" />;
}

SalesOrderLookup.propTypes = {
  inline: PropTypes.bool,
};

export default SalesOrderLookup;
