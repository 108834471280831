import React from 'react';

import get from 'lodash/get';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { allDialCodes } from 'app/helpers/country-data';
import { optionsForEmailOptOutDropdown } from 'helpers/contacts';
import { getCustomFieldForEntityPicker, getDefaultValues } from 'helpers/custom-fields';
import { displayFieldsToFormValues, getOptions } from 'helpers/display-fields';
import { stripDialCodeForEmptyNumber } from 'helpers/utils';
import countriesData from 'ui/FormControls/CountryPicker/countries.json';

import NewResourceModal from '../NewResourceModal';

function serializeFormData(formData) {
  const payload = { custom: {} };

  Object.keys(formData).forEach((key) => {
    if (key.startsWith('custom__')) {
      payload.custom[key.substring(8)] = formData[key] || null;
    } else {
      payload[key] = formData[key] || null;
    }
  });

  return payload;
}

function getAccountNameAndId(value) {
  return {
    account_name: isObject(value) ? value.label : value,
    account_id: isObject(value) ? value.value : null,
  };
}

function getOnChangeValuePayload(value, name) {
  // if user picks an existing company from AccountLookup
  // extract name and id from lookup result object
  if (name === 'account') {
    return getAccountNameAndId(value);
  }
  if (name === 'country') {
    return {
      country: get(
        countriesData.find((c) => c.Code === value),
        'Name',
        ''
      ),
    };
  }
  return { [name]: value };
}

function setInitialFormData(displayFields, initialValues) {
  return displayFieldsToFormValues(displayFields, (field) => initialValues[field.name] || '');
}

function getParentAccountId(account, opportunity) {
  if (account) return account.id;
  if (opportunity) return opportunity.account_id;
  return '';
}

function getParentAccountName(account, opportunity) {
  if (account) return account.name;
  if (opportunity) return opportunity.account_name;
  return '';
}

function getParent(account, opportunity) {
  if (!(account || opportunity)) return null;
  return {
    id: getParentAccountId(account, opportunity),
    name: getParentAccountName(account, opportunity),
  };
}

function getDefaultCountry(usersCountryCode) {
  return get(
    countriesData.find((c) => c.Code === usersCountryCode),
    'Name',
    ''
  );
}

class NewContactModalContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      account,
      opportunity,
      displayFields,
      usersCountryCode,
      userId,
      mobilePhone,
      firstName,
      lastName,
      email,
      title,
    } = props;

    const parent = getParent(account, opportunity);
    this.initialValues = {
      country: getDefaultCountry(usersCountryCode),
      user_id: userId,
      mobile_phone: mobilePhone,
      first_name: firstName,
      last_name: lastName,
      email,
      title,
    };
    this.accountValues = parent
      ? {
          account: parent.name,
          account_id: parent.id,
          account_name: parent.name,
        }
      : {};
    this.opportunityValues = opportunity ? { opportunity_id: opportunity.id } : {};

    this.state = {
      formData: {
        ...setInitialFormData(displayFields, this.initialValues),
        ...getDefaultValues(props.customFields, true),
        ...this.accountValues,
        ...this.opportunityValues,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {
      this.setState({
        formData: {
          ...setInitialFormData(this.props.displayFields, this.initialValues),
          ...getDefaultValues(this.props.customFields, true),
          ...this.accountValues,
          ...this.opportunityValues,
        },
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { formData } = this.state;
    const payload = serializeFormData({
      ...formData,
      phone: stripDialCodeForEmptyNumber(formData.phone, allDialCodes),
      home_phone: stripDialCodeForEmptyNumber(formData.home_phone, allDialCodes),
      mobile_phone: stripDialCodeForEmptyNumber(formData.mobile_phone, allDialCodes),
      account_phone: stripDialCodeForEmptyNumber(formData.account_phone, allDialCodes),
    });

    delete payload.account;

    this.props
      .onSubmit({
        ...payload,
        email_opt_out: payload.email_opt_out === null ? false : payload.email_opt_out,
      })
      .then(() => this.props.onHide());
  };

  onChange = (e) => {
    if (e) {
      const payload = getOnChangeValuePayload(e.target.value, e.target.name);
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          ...payload,
        },
      }));
    }
  };

  onCancel = (e) => {
    e.preventDefault();
    this.props.onHide();
  };

  mapField = (field, index) => {
    const { formData } = this.state;
    const {
      createInProgress,
      customFields,
      account,
      opportunity,
      users,
      usersCountryCode,
      timezones,
    } = this.props;
    const commonProperties = {
      value: formData[field.name] || '',
      onChange: this.onChange,
      disabled: field.readonly || createInProgress,
      compactLookup: false,
      autoFocus: index === 0,
    };

    const phoneCountryCode = {
      defaultCountry: usersCountryCode.toLowerCase(),
    };

    if (field.name === 'email_opt_out' && !commonProperties.value) {
      commonProperties.value = false;
    }

    const customObjectLookupProps = {
      customFieldId: null,
      pickerKey: '',
    };

    if (['List', 'MultipleSelect'].includes(field.type)) {
      commonProperties.options = getOptions(field, customFields, formData, true);
    }

    if (field.type === 'CustomObjectLookup') {
      const entityPicker = getCustomFieldForEntityPicker(field, customFields);
      customObjectLookupProps.customFieldId = entityPicker.custom_field_id;
      customObjectLookupProps.pickerKey = entityPicker.picker_key;
    }

    const mapping = {
      first_name: { required: true },
      last_name: { required: true },
      account: {
        allowNonExistingValue: true,
        disabled: !!(account || opportunity) || createInProgress,
        type: account || opportunity ? 'String' : 'AccountLookup',
        tooltip:
          "If the company you're searching for does not exist in Spiro, a new company record will be created.",
      },
      country: {
        type: 'Country',
        value: get(
          countriesData.find((c) => c.Name === formData[field.name]),
          'Code',
          undefined
        ),
      },
      user_id: {
        options: users,
        removable: false,
        filterByLabel: true,
      },
      email_opt_out: {
        options: optionsForEmailOptOutDropdown,
        removable: false,
        filter: false,
      },
      phone: phoneCountryCode,
      mobile_phone: phoneCountryCode,
      home_phone: phoneCountryCode,
      account_phone: phoneCountryCode,
      timezone: {
        options: timezones,
      },
    };

    return mapping[field.name]
      ? { ...field, ...commonProperties, ...mapping[field.name] }
      : { ...field, ...commonProperties, ...customObjectLookupProps };
  };

  render() {
    const { show, displayFields, createInProgress, t } = this.props;

    const fields = displayFields.map((field, index) => this.mapField(field, index));

    return (
      <NewResourceModal
        title={t('localization.contact.create')}
        show={show}
        fields={fields}
        loading={createInProgress}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        formName="new-contact-form"
      />
    );
  }
}

NewContactModalContainer.defaultProps = {
  usersCountryCode: 'US',
  account: undefined,
  opportunity: undefined,
  mobilePhone: null,
  firstName: '',
  lastName: '',
  email: '',
  title: '',
};

NewContactModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  createInProgress: PropTypes.bool.isRequired,
  displayFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  customFields: PropTypes.object.isRequired,
  usersCountryCode: PropTypes.string,
  account: PropTypes.object,
  opportunity: PropTypes.object,
  mobilePhone: PropTypes.string,
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.string,
  timezones: PropTypes.array.isRequired,
};

export default withTranslation()(NewContactModalContainer);
