import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import cs from 'classnames';
import PropTypes from 'prop-types';

import ImageLink from './ImageLink';
import SpiroLinkify from '../SpiroLinkify';
import VCard from './VCard';

import styles from './ChatContent.module.scss';

export default function ChatContent({
  position,
  text = '',
  mediaUrls = [],
  time = '',
  status = 'sent',
  ...props
}) {
  const vCards = mediaUrls.filter((url) => url.content_type?.includes('vcard'));

  return (
    <>
      {(!vCards.length || (vCards.length && text)) && (
        <div
          className={cs(styles.container, {
            [styles['content-left']]: position === 'left',
            [styles['content-right']]: position === 'right',
          })}
        >
          <div
            className={cs(styles.content, {
              [styles['content-contact']]: position === 'left',
              [styles['content-user']]: position === 'right',
              [styles['not-delivered']]: position === 'right' && status === 'failed',
            })}
          >
            <SpiroLinkify>
              <p>{text}</p>
            </SpiroLinkify>
            {mediaUrls.map((url) => {
              if (url.content_type?.includes('vcard')) return null;
              return <ImageLink key={url.url} file={url} hasText={text.length > 0} {...props} />;
            })}
            <div className={styles.chat__time}>
              <p
                className={
                  position === 'left' ? styles['chat__time--left'] : styles['chat__time--right']
                }
              >
                {time}
              </p>
            </div>
          </div>

          {position === 'right' && status === 'failed' && (
            <div className={styles.warning}>
              <InfoOutlinedIcon fontSize="small" />
              <span>Not delivered</span>
            </div>
          )}
        </div>
      )}

      {vCards.map((card) => (
        <div
          className={cs(styles.container, {
            [styles['content-left']]: position === 'left',
            [styles['content-right']]: position === 'right',
          })}
          key={card.url}
        >
          <VCard url={card.url} position={position} text={text} />
        </div>
      ))}
    </>
  );
}

ChatContent.propTypes = {
  position: PropTypes.string.isRequired,
  text: PropTypes.string,
  mediaUrls: PropTypes.array,
  time: PropTypes.string,
  status: PropTypes.string,
};
