import PropTypes from 'prop-types';

import useCurrency from 'hooks/useCurrency';
import CurrencyTextField from 'lib/CurrencyField';

export default function UserCurrencyTextField({
  component: Component = CurrencyTextField,
  ...props
}) {
  const { symbol } = useCurrency();
  return <Component currency={symbol} {...props} />;
}

UserCurrencyTextField.propTypes = {
  component: PropTypes.func,
};
