import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';

function ModalFooter(props) {
  return (
    <DialogActions sx={{ padding: '20px' }} {...props}>
      {props.children}
    </DialogActions>
  );
}

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;
