import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== 'isCompact',
})(({ isCompact }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent !important',
    '&.Mui-disabled': {
      backgroundColor: isCompact ? undefined : 'rgba(128, 128, 128, 0.08)',
    },
  },
  '& input.Mui-disabled': {
    '-webkit-text-fill-color': isCompact ? undefined : 'inherit',
  },
}));

export default function TextField({
  className = '',
  variant = 'filled',
  fullWidth = true,
  isCompact = false,
  required = false,
  ...props
}) {
  return (
    <StyledTextField
      {...props}
      fullWidth={fullWidth}
      variant={variant}
      autoComplete="off"
      className={className}
      required={required}
      data-cy={`${props.name}-field`}
      isCompact={isCompact}
    />
  );
}

TextField.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  isCompact: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
