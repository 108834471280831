import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import useInfiniteScroll from 'app/hooks/useInfiniteScroll';
import BouncingDots from 'ui/Loaders/BouncingDots/BouncingDots';

import DateHelper from '../../../../helpers/DateHelper';
import DateHeader from '../DateHeader';
import NotificationBody from './NotificationBody';
import NoNotifications from '../NoNotifications';
import NotificationsAvatar from '../NotificationsAvatar';
import NotificationSubject from './NotificationSubject';

import styles from './Notifications.module.scss';

function Notifications({ notifications, isLoading, fetchNotifications, meta }) {
  const { handleRef } = useInfiniteScroll(isLoading, meta, fetchNotifications);

  if (Object.keys(notifications).length === 0 && !isLoading) {
    return <NoNotifications />;
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.wrapper} ${isLoading ? styles.loading : ''}`}>
        {Object.keys(notifications).map((group) => (
          <div key={uuidv4()} className={styles.group}>
            <DateHeader date={group} />
            {notifications[group].map((notification, index) => (
              <div
                ref={notifications[group].length - 1 === index ? handleRef : null}
                key={notification.id}
                className={
                  notification.is_read ? styles.notification : styles['unread-notification']
                }
              >
                <NotificationsAvatar
                  type={notification.notification_type}
                  isRead={notification.is_read}
                />
                <div className={styles.info}>
                  <NotificationSubject
                    subject={notification.subject}
                    link={notification.subject_web_link}
                    additionalData={notification.additional_data}
                    type={notification.notification_type}
                  />
                  {(notification.body || notification?.import) && (
                    <NotificationBody body={notification.body} importData={notification?.import} />
                  )}
                </div>
                <span className={styles.time}>
                  {DateHelper.formatDate(notification.created_at, 'hh:mm A')}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
      {isLoading && (
        <div className={styles.loader}>
          <BouncingDots grey />
        </div>
      )}
    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    nextPage: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Notifications;
