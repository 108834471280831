import PropTypes from 'prop-types';

import Select from 'lib/Select';

import countries from './countries.json';

const options = countries.map((country) => ({
  value: country.Code,
  label: country.Name,
}));

export default function CountrySelect({ component: SelectComponent = Select, ...props }) {
  return <SelectComponent {...props} options={options} />;
}

CountrySelect.propTypes = {
  component: PropTypes.func,
};
