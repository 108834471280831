import PropTypes from 'prop-types';

import ChatContent from './ChatContent';

import styles from './ChatItem.module.scss';

export default function ChatItem({ userName, text, inbound, time, mediaUrls = [], ...props }) {
  return (
    <div className={styles.item}>
      <ChatContent
        text={text}
        position={inbound ? 'left' : 'right'}
        mediaUrls={mediaUrls}
        time={time}
        {...props}
      />
    </div>
  );
}

ChatItem.propTypes = {
  userName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  inbound: PropTypes.bool.isRequired,
  mediaUrls: PropTypes.array,
  time: PropTypes.string.isRequired,
};
