import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

export default function SanitizedText({ text, as: Element = 'div', className = '' }) {
  return <Element className={className} dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />;
}

SanitizedText.propTypes = {
  text: PropTypes.PropTypes.string.isRequired,
  className: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
