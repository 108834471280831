import cs from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import ChatGroup from './ChatGroup';

export default function Chat({ messages, className = '', ...props }) {
  return (
    <div className={cs('chat', className)}>
      {Object.keys(messages).map((key) => (
        <ChatGroup key={uuidv4()} name={key} messages={messages[key]} {...props} />
      ))}
    </div>
  );
}

Chat.propTypes = {
  messages: PropTypes.object.isRequired,
  className: PropTypes.string,
};
