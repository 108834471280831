import React from 'react';

import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';

import useHotjar from 'hooks/useHotjar';
import { showModal } from 'modals/state/actions';
import Contact from 'models/Contact';
import { getContacts } from 'services/search';
import { selectEmailClient } from 'state/user/selectors';

export default function SpiroLinkify({ children, stopPropagation = false }) {
  const emailClient = useSelector(selectEmailClient);
  const dispatch = useDispatch();
  const { logCustomEvent } = useHotjar();

  const onClick = (e, href) => {
    if (stopPropagation) e.stopPropagation();

    if (href.startsWith('mailto:')) {
      const email = href.substring(7);
      e.preventDefault();

      if (emailClient === 'gmail') {
        window.open(
          `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(email)}`,
          '_blank'
        );
      } else if (emailClient === 'spiro') {
        getContacts({
          query: email,
          sort: 'email',
        }).then((res) => {
          if (res.contacts.length > 0) {
            dispatch(
              showModal('SEND_EMAIL_MODAL', {
                show: true,
                contact: new Contact(res.contacts[0]),
              })
            );
            logCustomEvent('email_modal_opened');
          } else {
            dispatch(
              showModal('SEND_EMAIL_MODAL', {
                show: true,
                contact: { name: '', email, contactId: undefined, invalid: true },
              })
            );
            logCustomEvent('email_modal_opened');
          }
        });
      } else {
        window.open(`mailto:${encodeURIComponent(email)}`, '_blank');
      }
    } else {
      window.open(href, '_blank', 'noopener,noreferrer');
    }
  };

  const linkDecorator = (decoratedHref, decoratedText, key) => (
    <a
      href={decoratedHref}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#f06223', textDecoration: 'none' }}
      onClick={(e) => onClick(e, decoratedHref)}
    >
      {decoratedText}
    </a>
  );

  const processChildren = (child) => {
    if (typeof child === 'string') {
      return <Linkify componentDecorator={linkDecorator}>{child}</Linkify>;
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        children: React.Children.map(child.props.children, processChildren),
        style: { ...child.props.style, textDecoration: 'none' },
      });
    }

    return child;
  };

  if (typeof children.props?.isExpanded === 'boolean') {
    return <Linkify componentDecorator={linkDecorator}>{children}</Linkify>;
  }

  return <>{React.Children.map(children, processChildren)}</>;
}

SpiroLinkify.propTypes = {
  children: PropTypes.node.isRequired,
  stopPropagation: PropTypes.bool,
};
